import { buildSchema } from '@camberi/firecms'

const merchantSchema = buildSchema({
  name: 'Succursale',
  properties: {
    name: {
      dataType: 'string',
      title: 'Nom',
      validation: {
        required: true
      }
    },
    address: {
      dataType: 'string',
      title: 'Adresse',
      config: {
        multiline: true
      }
    },
    postalCode: {
      dataType: 'string',
      title: 'Numéro postal'
    },
    locality: {
      dataType: 'string',
      title: 'Localité'
    },
    region: {
      dataType: 'string',
      title: 'Région'
    },
    description: {
      dataType: 'string',
      title: 'Description',
      config: {
        multiline: true
      }
    },
    website: {
      dataType: 'string',
      title: 'Site web',
      config: {
        url: true
      }
    },
    category: {
      dataType: 'reference',
      title: 'Catégorie',
      collectionPath: 'categories'
    },
    iban: {
      dataType: 'string',
      title: 'IBAN'
    },
    paymentTo: {
      dataType: 'string',
      title: 'Bénéficiaire'
    },
    paymentPostalCode: {
      dataType: 'string',
      title: 'Paiement numéro postal'
    },
    paymentLocality: {
      dataType: 'string',
      title: 'Paiement localité'
    },
    visible: {
      dataType: 'boolean',
      title: 'Visible'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp',
      autoValue: 'on_create'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp',
      autoValue: 'on_update'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default merchantSchema
