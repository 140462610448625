import { buildCollection } from '@camberi/firecms'
import labelSchema from 'src/collections/label/labelSchema'

const labelCollection = buildCollection({
  relativePath: 'labels',
  schema: labelSchema,
  name: 'Libellés',
  defaultSize: 'm'
})

export default labelCollection
