import { buildSchema } from '@camberi/firecms'

const regionSchema = buildSchema({
  name: 'Région',
  customId: true,
  properties: {
    value: {
      dataType: 'string',
      title: 'Valeur',
      validation: {
        required: true
      }
    },
    label: {
      dataType: 'string',
      title: 'Libellé',
      validation: {
        required: true
      }
    },
    createdAt: {
      dataType: 'timestamp',
      title: 'Crée le',
      autoValue: 'on_create'
    },
    updatedAt: {
      dataType: 'timestamp',
      title: 'Modifié le',
      autoValue: 'on_update'
    }
  }
})

export default regionSchema
