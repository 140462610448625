import { buildCollection, EntityCollectionView } from '@camberi/firecms'
import saleSchema from 'src/collections/sale/saleSchema'

const saleCollection: EntityCollectionView<typeof saleSchema> = buildCollection({
  name: 'Ventes',
  relativePath: 'sales',
  schema: saleSchema,
  deleteEnabled: true,
  excludedProperties: ['createdAt', 'updatedAt'],
  defaultSize: 'xs'
})

export default saleCollection
