import { buildSchema } from '@camberi/firecms'

const saleSchema = buildSchema({
  name: 'Vente',
  properties: {
    customer: {
      title: 'Client',
      dataType: 'reference',
      collectionPath: 'users',
      previewProperties: ['firstName', 'lastName']
    },
    deal: {
      title: 'Offre',
      dataType: 'reference',
      collectionPath: 'deals'
      // textSearchDelegate: dealsSearchDelegate,
      // previewProperties: ["name", "main_image"]
    },
    quantity: {
      title: 'Quantité',
      dataType: 'number'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp',
      autoValue: 'on_create'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp',
      autoValue: 'on_update'
    }
  },
  defaultValues: {}
})

export default saleSchema
