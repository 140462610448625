import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import Copyright from 'src/components/molecules/Copyright'
import getFunctionUrl from 'src/utils/getFunctionUrl'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export default function MigrationView() {
  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        <MigrationTool
          migration={'1615286360'}
          number={'1'}
          title={'Flag "visible"'}
          content={'Ajouter un flag "visible" = true là où cela est nécessaire. (19.02.2021)'}
        />

        <MigrationTool
          migration={'1615286363'}
          number={'2'}
          title={'Normaliser les "users"'}
          content={
            'Ajouter une valeur vide pour les champs "firstName", "lastName" et "email" à la place de "undefined" (09.03.2021)'
          }
        />
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

type MigrationToolType = {
  migration: string
  number: string
  title: string
  content: string
}

const MigrationTool = ({ migration, number, title, content }: MigrationToolType) => {
  const classes = useStyles()

  const [isSubmitting, setIsSubmitting] = useState<true | false>(false)
  const [resultSuccess, setResultSuccess] = useState<string>('')
  const [resultError, setResultError] = useState<string>('')

  const handleMigration = async () => {
    setResultSuccess('')
    setResultError('')
    setIsSubmitting(true)
    try {
      const url = getFunctionUrl(`migrateData${migration}`)
      const result = await fetch(url, {
        headers: { 'Content-Type': 'application/json' }
      })
      setResultSuccess(JSON.stringify(await result.json(), null, 2))
      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
      setResultError(e.message)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <Paper className={'classes.paper'} style={{ padding: '16px' }}>
          <Typography variant="h6" color="textSecondary">
            <Badge
              badgeContent={number}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}>
              {title}
            </Badge>
          </Typography>
          <div className={classes.container}>{content}</div>
          <div className={classes.container}>
            <Button
              disabled={isSubmitting}
              onClick={handleMigration}
              variant="contained"
              color="primary">
              Exécuter migration
            </Button>
          </div>

          {isSubmitting && <CircularProgress className={classes.loading} />}
          {resultSuccess && <Box bgcolor="success.main">{resultSuccess}</Box>}
          {resultError && <Box bgcolor="error.main">{resultError}</Box>}
        </Paper>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)
