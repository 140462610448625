import { buildCollection, EntityCollectionView } from '@camberi/firecms'
import imageSchema from 'src/collections/image/imageSchema'

const imageCollection: EntityCollectionView<typeof imageSchema> = buildCollection({
  name: 'Images',
  relativePath: 'images',
  schema: imageSchema,
  deleteEnabled: true,
  excludedProperties: ['createdAt', 'updatedAt'],
  defaultSize: 'm'
})

export default imageCollection
