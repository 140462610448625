import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import ReimbursementView from 'src/components/views/reimbursement/ReimbursementView'

const reimbursementAdditionalView: AdditionalView = {
  path: '/reimbursements',
  name: 'Remboursements',
  group: 'Gestion',
  view: <ReimbursementView />
}

export default reimbursementAdditionalView
