import { buildCollection, EntityCollectionView } from '@camberi/firecms'
import merchantSchema from 'src/collections/merchant/merchantSchema'
import { merchantsSearchDelegate } from 'src/utils/algolia'
import subsidiarySchema from 'src/collections/merchant/subsidiarySchema'

const merchantCollection: EntityCollectionView<typeof merchantSchema> = buildCollection({
  name: 'Commerçants',
  relativePath: 'merchants',
  schema: merchantSchema,
  deleteEnabled: true,
  defaultSize: 'm',
  textSearchDelegate: merchantsSearchDelegate,
  excludedProperties: ['createdAt', 'updatedAt'],
  subcollections: [
    {
      relativePath: 'subsidiaries',
      schema: subsidiarySchema,
      name: 'Succursales',
      filterableProperties: ['name']
    }
  ]
})

export default merchantCollection
