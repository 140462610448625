import { Authenticator, CMSApp } from '@camberi/firecms'
import firebase from 'firebase'
import i18next from 'i18next'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import categoryCollection from 'src/collections/category'
import dealCollection from 'src/collections/deal'
import imageCollection from 'src/collections/image'
import labelCollection from 'src/collections/label'
import merchantCollection from 'src/collections/merchant'
import regionCollection from 'src/collections/region'
import saleCollection from 'src/collections/sale'
import userCollection from 'src/collections/user'
import {
  fileAdditionalView,
  integrityAdditionalView,
  migrationAdditionalView,
  notificationAdditionalView,
  reimbursementAdditionalView,
  searchIndexesAdditionalView,
  statisticAdditionalView,
  taskAdditionalView
} from 'src/components/views/index'
import { firebaseConfig } from './firebaseConfig'
// import 'src/utils/console'
import './i18next-init'
import logo from './images/yodo_logo.png'
import * as serviceWorker from './serviceWorker'

const myAuthenticator: Authenticator = (user?: firebase.User) => {
  console.log('Allowing access to', user?.email)
  const remoteConfig = firebase.remoteConfig()
  return remoteConfig
    .fetchAndActivate()
    .then<boolean>(() => {
      // const adminEmails: string[] = String(process.env.REACT_APP_ADMIN_EMAILS).split(',')
      const adminEmails = JSON.parse(remoteConfig.getString('adminEmails'))
      const email = user?.email ?? ''

      // Test analytics
      firebase.analytics().logEvent('login', {
        method: 'login',
        from: 'admin.yodo.ch'
      })
      return adminEmails.includes(email)
    })
    .catch<boolean>((err) => {
      console.error('Firebase Remote Config failed to initialize', err)
      return false
    })
}

const onFirebaseInit = (config: Object) => {
  // models.firestore().useEmulator("localhost", 8080);
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Suspense fallback="loading">
      <CMSApp
        name={`Yodo Club Admin ${process.env.REACT_APP_APPLICATION_CONTEXT_NAME}`}
        authentication={myAuthenticator}
        signInOptions={[
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ]}
        allowSkipLogin={false}
        logo={logo}
        navigation={[
          merchantCollection,
          dealCollection,
          saleCollection,
          categoryCollection,
          imageCollection,
          labelCollection,
          regionCollection,
          userCollection
        ]}
        additionalViews={[
          reimbursementAdditionalView,
          notificationAdditionalView,
          statisticAdditionalView,
          // backupAdditionalView,
          searchIndexesAdditionalView,
          fileAdditionalView,
          integrityAdditionalView,
          migrationAdditionalView,
          taskAdditionalView
        ]}
        onFirebaseInit={onFirebaseInit}
        // In the production environment, the configuration is fetched from the environment automatically
        firebaseConfig={process.env.NODE_ENV !== 'production' ? firebaseConfig : undefined}
        toolbarExtraWidget={<></>}
      />
    </Suspense>
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister()
