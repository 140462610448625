import { buildCollection } from '@camberi/firecms'
import userAdditionalColumn from './userAdditionalColumn'
import userSchema from './userSchema'
import { usersSearchDelegate } from 'src/utils/algolia'

const userCollection = buildCollection({
  relativePath: 'users',
  schema: userSchema,
  name: 'Utilisateurs',
  textSearchDelegate: usersSearchDelegate,
  excludedProperties: ['createdAt', 'updatedAt', 'password'],
  additionalColumns: [userAdditionalColumn],
  defaultSize: 'xs',
  initialFilter: { roles: ['array-contains-any', ['merchant', 'customer']] }
})

export default userCollection
