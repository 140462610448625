import { buildSchema } from '@camberi/firecms'

const merchantSchema = buildSchema({
  name: 'Commerçant',
  properties: {
    name: {
      title: 'Nom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    subsidiaries: {
      title: 'Succursales',
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          name: {
            title: 'Nom',
            dataType: 'string',
            validation: {
              required: true
            }
          },
          address: {
            title: 'Adresse',
            dataType: 'string',
            config: {
              multiline: true
            }
          },
          postalCode: {
            title: 'Numéro postal',
            dataType: 'string'
          },
          locality: {
            title: 'Localité',
            dataType: 'string'
          }
        }
      }
    },
    showClientPersonalData: {
      title: 'Affiche les données personnelles client',
      description: 'Affiche les données personnelles client dans la console commerçant',
      dataType: 'boolean'
    },
    visible: {
      title: 'Visible',
      dataType: 'boolean'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp',
      autoValue: 'on_create'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp',
      autoValue: 'on_update'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default merchantSchema
