import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import BackupView from 'src/components/views/backup/BackupView'

const backupAdditionalView: AdditionalView = {
  path: '/backups',
  name: 'Backups',
  group: 'Admin',
  view: <BackupView />
}

export default backupAdditionalView
