import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import FileView from 'src/components/views/file/FileView'

const fileAdditionalView: AdditionalView = {
  path: '/files',
  name: 'Indexation fichiers',
  group: 'Admin',
  view: <FileView />
}

export default fileAdditionalView
