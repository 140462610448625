import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core'
import Copyright from 'src/components/molecules/Copyright'
import getFunctionCallable from 'src/utils/getFunctionCallable'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { config } from '@yodo/config/dist/config'
import { User } from '@yodo/types'
import firebase from 'firebase/app'
import {
  DataGrid,
  GridColDef,
  GridSortDirection,
  ValueFormatterParams
} from '@material-ui/data-grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Subsidiary } from '@yodo/types/dist/Merchant'
import ConfirmDialog from 'src/components/molecules/ConfirmDialog'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

export default function NotificationView() {
  const classes = useStyles()

  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [selectedDeals, setSelectedDeals] = useState<(string | number)[]>([])

  type State = {
    isModalOpen: boolean
    isSnackbarOpen: boolean
    resultMessage: string
  }
  const [state, setState] = useState<State>({
    isModalOpen: false,
    isSnackbarOpen: false,
    resultMessage: ''
  })

  // const handleState = (key: keyof State, value: any) => {
  //   setState({ ...state, [key]: value })
  // }

  const handleModalOpen = (isModalOpen: boolean) => {
    state.isModalOpen = isModalOpen
    setState({ ...state })
  }

  const handleOpenSnackbar = (resultMessage: string) => {
    setState({ ...state, isModalOpen: false, isSnackbarOpen: true, resultMessage: resultMessage })
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setState({ ...state, isSnackbarOpen: false, resultMessage: '' })
  }

  // Fetch all deals on air
  const [deals, dealLoading] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('dealsByHits')),
    {
      idField: 'id'
    }
  )

  // fetch all users
  const [users, userLoading] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('users')),
    {
      idField: 'id'
    }
  )

  const handleNotification = async () => {
    const sendNotifications = getFunctionCallable('sendNotifications')

    try {
      // we just extract the ids from the array of users
      const userIds = selectedUsers.map(({ id }) => id)
      return await sendNotifications({ dealIds: selectedDeals, userIds: userIds })
    } catch (e) {
      console.error(e)
    }
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Grid container>
              <Grid item xs={9}>
                <Autocomplete
                  multiple
                  loading={userLoading ?? false}
                  id="tags-standard"
                  options={users ?? []}
                  getOptionLabel={(option) =>
                    `${String(option?.firstName)} ${String(option?.lastName)} (${String(
                      option?.id
                    )}) ${option?.fcmToken ? '' : '⚠️ missing token!'}`
                  }
                  onChange={(event: any, users: User[]) => {
                    setSelectedUsers(users)
                  }}
                  defaultValue={undefined}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Utilisateurs"
                      placeholder="Utilisateurs"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={selectedUsers.length === 0 || selectedDeals.length === 0}
                  onClick={() => handleModalOpen(true)}
                  className={classes.btnSend}>
                  Envoi Notifications ({selectedUsers.length} x {selectedDeals.length})
                </Button>
                <ConfirmDialog
                  titleText="Envoi notifiations"
                  contentText={`Vous être sur le point d'envoyer ${selectedUsers.length} x ${selectedDeals.length} messages?`}
                  actionText={'Envoyer'}
                  open={state.isModalOpen}
                  onPerformAction={async () => {
                    // handleModalOpen(false)
                    const result = await handleNotification()
                    handleOpenSnackbar(JSON.stringify(result))
                  }}
                  onCloseAction={() => {
                    handleModalOpen(false)
                  }}
                />
              </Grid>
            </Grid>

            <Snackbar
              open={state.isSnackbarOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity="success">
                {state.resultMessage}
              </Alert>
            </Snackbar>

            <Typography variant="h6" color="textSecondary" className={classes.gridHeader}>
              Offres exclusives "en ligne"
            </Typography>

            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={deals ?? []}
                sortModel={[
                  {
                    field: 'ranking',
                    sort: 'desc' as GridSortDirection
                  }
                ]}
                columns={columns}
                pageSize={50}
                checkboxSelection
                onSelectionModelChange={({ selectionModel }) => {
                  setSelectedDeals(selectionModel)
                }}
                loading={dealLoading}
              />
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

const columns: GridColDef[] = [
  {
    field: 'isNotified',
    headerName: 'Notifié?',
    width: 110,
    align: 'left',
    headerAlign: 'left',
    renderCell: (params: ValueFormatterParams) => {
      return (
        <Checkbox
          disabled
          checked={Boolean(params.value)}
          inputProps={{ 'aria-label': 'checkbox' }}
        />
      )
    }
  },
  {
    field: 'name',
    headerName: 'Nom',
    width: 350,
    align: 'left',
    headerAlign: 'left'
  },
  { field: 'ranking', headerName: 'Ranking', type: 'number', width: 160 },
  {
    field: 'subsidiaries',
    width: 320,
    headerName: 'Succursales',
    // Manual sorting of columns since we have to deals with an array of values
    sortComparator: (v1, v2, param1, param2) => {
      const value1 =
        Array.isArray(param1?.value) && typeof param1?.value[0] !== 'undefined'
          ? param1?.value[0].name
          : ''

      const value2 =
        Array.isArray(param2?.value) && typeof param2?.value[0] !== 'undefined'
          ? param2?.value[0].name
          : ''

      if (value1 < value2) {
        return -1
      }
      if (value1 > value2) {
        return 1
      }
      return 0
    },
    renderCell: (params: ValueFormatterParams) => {
      let subsidiaries: string[] = []
      if (Array.isArray(params.value)) {
        subsidiaries = params.value?.map((subsidiary: Subsidiary) => {
          return subsidiary.name
        })
      }
      return <div>{subsidiaries.join(', ')}</div>
    }
  },
  { field: 'price', headerName: "Prix avant l'offre", type: 'number', width: 160 },
  { field: 'priceWithDiscount', headerName: 'Prix exclusif', type: 'number', width: 150 },
  { field: 'priceFinal', headerName: 'Prix affiché client', type: 'number', width: 160 }
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 500,
      '& > * + *': {
        marginTop: theme.spacing(3)
      }
    },
    gridHeader: {
      marginTop: theme.spacing(3)
    },
    btnSend: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(5)
    }
  })
)
