import { buildCollection, EntityCollectionView } from '@camberi/firecms'
import dealSchema from 'src/collections/deal/dealSchema'
import imageColumn from 'src/collections/deal/imageColumn'
import { dealsSearchDelegate } from 'src/utils/algolia'

const dealCollection: EntityCollectionView<typeof dealSchema> = buildCollection({
  name: 'Offres',
  relativePath: 'deals',
  schema: dealSchema,
  deleteEnabled: true,
  additionalColumns: [imageColumn],
  textSearchDelegate: dealsSearchDelegate,
  excludedProperties: [
    'image',
    'description',
    'variants',
    'subsidiaries',
    'termsAndConditions',
    'createdAt',
    'updatedAt'
  ],
  defaultSize: 's'
})

export default dealCollection
