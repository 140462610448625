import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import TaskView from 'src/components/views/task/TaskView'

const taskAdditionalView: AdditionalView = {
  path: '/tasks',
  name: 'Tâches planifiées',
  group: 'Admin',
  view: <TaskView />
}

export default taskAdditionalView
