import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import Copyright from 'src/components/molecules/Copyright'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import getFunctionCallable from 'src/utils/getFunctionCallable'

export default function SearchIndexView() {
  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        {['users', 'deals', 'merchants'].map((collection) => {
          return <CardCollectionIndex key={collection} collection={collection} />
        })}
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    loading: {
      marginLeft: theme.spacing(3)
    }
  })
)

type CardCollectionIndexType = {
  collection: string
}
const CardCollectionIndex = ({ collection }: CardCollectionIndexType) => {
  const classes = useStyles()

  const [isSubmitting, setIsSubmitting] = useState<true | false>(false)
  const [resultSuccess, setResultSuccess] = useState<string>('')
  const [resultError, setResultError] = useState<string>('')

  const [state, setState] = useState({
    hasIndexReset: false
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const handleIndexInAlgolia = async () => {
    setResultSuccess('')
    setResultError('')
    const indexInAlgolia = getFunctionCallable('indexInAlgolia')
    setIsSubmitting(true)
    try {
      // we just extract the ids from the array of users
      // const userIds = selectedUsers.map(({ id }) => id)
      const result = await indexInAlgolia({ collection: collection, reset: state.hasIndexReset })
      setResultSuccess(JSON.stringify(result, null, 2))
      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
      setResultError(JSON.stringify(e.message, null, 2))
    }
  }

  return (
    <Grid key={collection} item xs={12} md={4} lg={4}>
      <Paper className={'classes.paper'} style={{ padding: '16px' }}>
        <Typography variant="h6" color="textSecondary">
          Indexation "{collection}"
        </Typography>
        <div className={classes.container}>Indéxer le contenu de la collection "{collection}"</div>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={handleIndexInAlgolia}>
              Indexer "{collection}"
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.hasIndexReset}
                  onChange={handleChange}
                  name="hasIndexReset"
                  color="primary"
                />
              }
              label="Reset index"
            />
            <Box color="text.secondary">Supprime les données existantes avant l'indexation</Box>
          </Grid>
          <Grid item xs={12}>
            {isSubmitting && <CircularProgress className={classes.loading} />}
          </Grid>
          <Grid item xs={12}>
            {resultSuccess && <Box bgcolor="success.main">{resultSuccess}</Box>}
            {resultError && <Box bgcolor="error.main">{resultError}</Box>}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}
