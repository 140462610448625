import { config } from '@yodo/config/dist/config'

export default function getFunctionUrl(functionName: string) {
  return `https://${
    Number(process.env.REACT_APP_EMULATOR) ? 'localhost:5000' : config.get('region')
  }${
    Number(process.env.REACT_APP_EMULATOR)
      ? ''
      : `-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`
  }/${functionName}`
}
