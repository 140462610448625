import { buildSchema, EnumValues } from '@camberi/firecms'

const roles: EnumValues<string> = {
  admin: 'Administrateur',
  merchant: 'Commerçant',
  customer: 'client'
}

const userSchema = buildSchema({
  name: 'Utilisateur',
  properties: {
    firstName: {
      title: 'Prénom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    lastName: {
      title: 'Nom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    email: {
      title: 'Email',
      dataType: 'string',
      validation: {
        email: true,
        required: true
      }
    },
    birthDate: {
      title: 'Anniversaire',
      dataType: 'timestamp'
    },
    phoneNumber: {
      title: 'Téléphone',
      dataType: 'string'
    },

    password: {
      title: 'Mot de passe',
      dataType: 'string',
      description:
        'Laissez le champ vide pour ne pas changer le mot de passe. 6 caractères minimum.',
      validation: {
        min: 6
      }
    },
    roles: {
      title: 'Roles',
      dataType: 'array',
      validation: {
        min: 1
      },
      of: {
        dataType: 'string',
        config: {
          enumValues: roles
        }
      }
    },
    merchant: {
      title: 'Commerçant',
      dataType: 'reference',
      collectionPath: 'merchants'
    },
    visible: {
      dataType: 'boolean',
      title: 'Visible'
    },
    createdAt: {
      title: 'Crée le',
      dataType: 'timestamp',
      autoValue: 'on_create'
    },
    updatedAt: {
      title: 'Modifié le',
      dataType: 'timestamp',
      autoValue: 'on_update'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default userSchema
