import { Box, Container, Grid, Paper } from '@material-ui/core'
import React from 'react'
import Copyright from 'src/components/molecules/Copyright'
import Chart from './Charts'
import Deposits from './Deposits'
import Orders from './Orders'

export default function StatisticView() {
  // const classes = useStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            className={'fixedHeightPaper'}
            style={{ height: '280px', display: 'flex', padding: '16px' }}>
            <Chart />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={'fixedHeightPaper'} style={{ height: '280px', padding: '16px' }}>
            <Deposits />
          </Paper>
        </Grid>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Orders />
          </Paper>
        </Grid>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}
