import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { config } from '@yodo/config'
import { Merchant } from '@yodo/types'
import { Sale } from '@yodo/types/dist/Sale'
import firebase from 'firebase'
import React, { useEffect } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Copyright from 'src/components/molecules/Copyright'
import ButtonCheckData from 'src/components/views/reimbursement/ButtonCheckData'
import ButtonReimburse from 'src/components/views/reimbursement/ButtonReimburse'
import ListCompletedReimbursements from 'src/components/views/reimbursement/ListCompletedReimbursements'
import ListSales from 'src/components/views/reimbursement/ListSales'
import ListSubsidiaries from 'src/components/views/reimbursement/ListSubsidiaries'

export default function ReimbursementView() {
  const classes = useStyles()

  const [currentMerchantId, setCurrentMerchantId] = React.useState<string>('')
  const [currentMerchant, setCurrentMerchant] = React.useState<Merchant | undefined>()
  const [filteredSales, setFilteredSales] = React.useState<Sale[]>([])

  // Fetch all user integrity reports
  const [sales] = useCollectionData<Sale>(
    // @ts-ignore
    firebase
      .firestore()
      .collection(config.collection('sales'))
      .where(
        'merchant',
        '==',
        firebase
          .firestore()
          .doc(
            `${config.collection('merchants')}/${
              currentMerchantId === '' ? undefined : currentMerchantId
            }`
          )
      ),
    {
      idField: 'id'
    }
  )

  useEffect(() => {
    if (sales) {
      const _filteredSales = sales.filter((sale: Sale) => {
        return !sale?.reimbursementDate
      })
      setFilteredSales(_filteredSales)
    } else {
      setFilteredSales([])
    }
  }, [sales])

  // Fetch all merchants
  const [merchants] = useCollectionData<any>(
    firebase.firestore().collection(config.collection('merchants')).orderBy('name', 'asc'),
    {
      idField: 'id'
    }
  )

  useEffect(() => {
    if (currentMerchantId) {
      ;(async function loadMe() {
        const merchantDoc = await firebase
          .firestore()
          .doc(`${config.collection('merchants')}/${currentMerchantId}`)
          .get()

        if (merchantDoc.exists) {
          const merchant = merchantDoc.data() as Merchant
          setCurrentMerchant(merchant)
        }
      })()
    }
  }, [currentMerchantId])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentMerchantId(event.target.value as string)
  }

  const MenuProps = {
    PaperProps: {
      style: {
        width: 500
      }
    }
  }

  return (
    <Container maxWidth="lg" className={'classes.container'} style={{ paddingTop: '32px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={'classes.paper'} style={{ padding: '16px' }}>
            <Typography variant="h6" color="textSecondary" className={classes.gridHeader}>
              Remboursement des commerçants
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                {currentMerchantId && `(debug: ${currentMerchantId})`}
                <FormControl className={classes.formControl}>
                  <InputLabel id="merchants">Commerçants</InputLabel>
                  <Select
                    labelId="merchants"
                    id="merchants"
                    value={currentMerchantId}
                    autoWidth={true}
                    onChange={handleChange}
                    MenuProps={MenuProps}>
                    <ListSubheader>Remboursements en attente</ListSubheader>
                    {Array.isArray(merchants) &&
                      merchants?.map((merchant: Merchant) => {
                        return merchant.numberOfPendingReimbursements > 0 ? (
                          <MenuItem key={merchant.id} value={merchant.id}>
                            <ListItemText
                              primary={`${merchant.name} (${merchant.numberOfPendingReimbursements})`}
                            />
                          </MenuItem>
                        ) : null
                      })}
                    <ListSubheader>Rien à rembourser</ListSubheader>
                    {Array.isArray(merchants) &&
                      merchants?.map((merchant: Merchant) => {
                        return merchant.numberOfPendingReimbursements <= 0 ? (
                          <MenuItem key={merchant.id} value={merchant.id}>
                            <ListItemText primary={`${merchant.name}`} />
                          </MenuItem>
                        ) : null
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <ButtonCheckData />
              </Grid>
            </Grid>

            {filteredSales.length > 0 && currentMerchant && (
              <>
                <ListSales sales={filteredSales} />

                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <ListSubsidiaries merchant={currentMerchant} />
                  </Grid>

                  <Grid item xs={4}>
                    <ButtonReimburse sales={filteredSales} merchant={currentMerchant} />
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {currentMerchant && <ListCompletedReimbursements merchant={currentMerchant} />}

      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

/**
 *
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridHeader: {
      marginTop: theme.spacing(3)
    },
    formControl: {
      margin: theme.spacing(1),
      width: '100%'
      // maxWidth: 500
    }
  })
)
