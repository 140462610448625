import { Grid, Paper, Typography } from '@material-ui/core'
import ListSales from 'src/components/views/reimbursement/ListSales'
import React from 'react'
import { Merchant } from '@yodo/types'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase from 'firebase'
import { config } from '@yodo/config'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Reimbursement } from '@yodo/types/dist/Merchant'

type Props = {
  merchant: Merchant
}
export default function ListCompletedReimbursements({ merchant }: Props) {
  const classes = useStyles()
  const [reimbursements] = useCollectionData<any>(
    firebase
      .firestore()
      .collection(config.collection('merchants'))
      .doc(merchant.id)
      .collection('completedReimbursements'),
    {
      idField: 'id'
    }
  )

  return (
    <>
      {reimbursements &&
        reimbursements.map((reimbursement: Reimbursement) => {
          return (
            <Grid container spacing={3} key={reimbursement.id}>
              <Grid item xs={12}>
                <Paper className={'classes.paper'} style={{ padding: '16px' }}>
                  <Typography variant="h6" color="textSecondary" className={classes.gridHeader}>
                    Remboursement du {reimbursement.dateTime.toDate().toLocaleDateString('fr-CH')} @{' '}
                    {reimbursement.dateTime.toDate().toLocaleTimeString('fr-CH')}
                  </Typography>
                  <ListSales sales={reimbursement.sales} />
                </Paper>
              </Grid>
            </Grid>
          )
        })}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridHeader: {
      marginTop: theme.spacing(3)
    }
  })
)
