import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import StatisticView from 'src/components/views/statistic/StatisticView'

const statisticAdditionalView: AdditionalView = {
  path: '/statistics',
  name: 'Statistiques',
  group: 'Gestion',
  view: <StatisticView />
}

export default statisticAdditionalView
