import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import DataIntegrityView from 'src/components/views/integrity/DataIntegrityView'

const integrityAdditionalView: AdditionalView = {
  path: '/data-integrity',
  name: 'Intégrité données',
  group: 'Admin',
  view: <DataIntegrityView />
}

export default integrityAdditionalView
