import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import NotificationView from 'src/components/views/notification/NotificationView'

const notificationAdditionalView: AdditionalView = {
  path: '/notifications',
  name: 'Centre notifications',
  group: 'Admin',
  view: <NotificationView />
}

export default notificationAdditionalView
