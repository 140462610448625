import { AdditionalView } from '@camberi/firecms'
import React from 'react'
import MigrationView from 'src/components/views/migration/MigrationView'

const migrationAdditionalView: AdditionalView = {
  path: '/migrations',
  name: 'Migrations',
  group: 'Admin',
  view: <MigrationView />
}

export default migrationAdditionalView
